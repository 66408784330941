<template>
  <card-component title="Profil Bilgileriniz" icon="account-circle">
    <form @submit.prevent="submit">
      <b-field label="Kullanıcı Adı" message="Zorunlu Alan">
        <b-input size="is-small" v-model="form.username" name="username" required/>
      </b-field>
      <b-field label="E-Posta" message="Zorunlu Alan">
        <b-input size="is-small" v-model="form.email" name="email" type="email" required/>
      </b-field>
      <hr/>
      <b-field>
        <div class="control">
          <button type="submit" class="button is-primary is-small" :class="{ 'is-loading': isLoading }">Güncelle</button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import {ProfileService as Service} from '@/services'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'ProfileUpdateForm',
  components: {
    CardComponent
  },
  data () {
    return {
      isLoading: false,
      form: {
        username: null,
        email: null
      }
    }
  },
  mounted () {
    this.form.username = this.$store.state.user.username
    this.form.email = this.$store.state.user.email
  },
  methods: {
    submit () {
      this.isLoading = true
      Service.update(this.form).then(res => {
        this.$store.dispatch('setBasic', {
          key: 'user',
          value: { ...this.$store.state.user, username: this.form.username, email: this.form.email }
        })
        this.$buefy.snackbar.open({ message: 'Güncellendi', queue: false })
      })
          .catch(err => this.$buefy.toast.open({ message: err.message, position: 'is-top-right', type: 'is-danger' }))
      .finally(() => this.isLoading = false)
    }
  }
}
</script>

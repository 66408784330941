<template>
  <card-component :title="`Parolanızı Güncelleyin`" icon="lock">
    <form @submit.prevent="submit">
      <b-field label="Mevcut Parolanız">
        <b-input v-model="form.password_current" size="is-small"
                 name="password_current" type="password" required autcomplete="current-password"/>
      </b-field>
      <hr/>
      <b-field label="Yeni Şifre" :message="newPasswordError ? newPasswordErrorMessage : ''">
        <b-input
            size="is-small"
            required
            expanded
            v-model="form.password"
            type="password"
            placeholder="Yeni Şifre"
            :class="{'is-danger': newPasswordError}"
            @input="validateNewPassword"/>
      </b-field>

      <b-field label="Yeni Şifre Tekrarı" :message="confirmNewPasswordError ? 'Şifre Eşleşmiyor' : ''">
        <b-input
            size="is-small"
            required
            expanded
            v-model="form.password_confirmation"
            type="password"
            placeholder="Yeni Şifre Tekrarı"
            :class="{'is-danger': confirmNewPasswordError}"
            @input="validateConfirmPassword"/>
      </b-field>
      <hr v-if="newPasswordError === false && confirmNewPasswordError === false"/>
      <b-field v-if="newPasswordError === false && confirmNewPasswordError === false">
        <div class="control is-flex">
          <!--
          <button type="button" @click="reset2fa" v-if="hasPermission('User.Reset2FA')" class="button is-small is-warning mr-3" :class="{ 'is-loading': isLoading }">
            2FA Kodunu Sıfırla
          </button>
          -->
          <button type="submit" class="button is-flex-grow-1 is-primary is-small" :class="{ 'is-loading': isLoading }">Güncelle</button>
        </div>
      </b-field>
      <hr/>

      <b-field>
        <div class="control is-flex">
          <b-button expanded @click="passGenerate" size="is-small" class="is-primary">Otomatik Şifre Oluştur</b-button>
          <b-button v-if="generatedPassword" @click="copy" size="is-small" class="is-info ml-3">Şifreyi kopyala</b-button>
        </div>
        <div class="control is-flex">
          <div v-if="generatedPassword">Oluşturulan şifre: {{generatedPassword}}</div>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import { ProfileService as Service } from '@/services'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'PasswordUpdateForm',
  components: {
    CardComponent
  },
  data() {
    return {
      isLoading: false,
      form: {
        password_current: null,
        password: null,
        password_confirmation: null
      },
      newPasswordError: true,
      confirmNewPasswordError: true,
      newPasswordErrorMessage: '',
      generatedPassword: null
    }
  },
  mounted(){
    this.validateNewPassword()
    this.validateConfirmPassword()
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.generatedPassword)
      this.$buefy.toast.open({
        message: this.$t('def.copied'),
        type: "is-success",
      })
      this.active = false
    },
    passGenerate() {
      this.generatedPassword = this.generateP(10)
      this.form.password_confirmation = this.generatedPassword
      this.form.password = this.generatedPassword
    },
    reset2fa() {
      this.isLoading = true
      Service.reset2fa()
          .then(() => {
            this.$buefy.snackbar.open({message: 'Kodunuz Sıfırlandı', queue: false})
          })
          .catch(err => this.$buefy.toast.open({message: err.message, position: 'is-top-right', type: 'is-danger'}))
          .finally(() => this.isLoading = false)
    },
    submit() {
      this.isLoading = true
      Service.passwordChange(this.form)
          .then(() => {
            this.$buefy.snackbar.open({message: 'Parolanız Güncellendi', queue: false})
            this.$router.push({name: 'logout'})
          })
          .catch(err => this.$buefy.toast.open({message: err.message, position: 'is-top-right', type: 'is-danger'}))
          .finally(() => this.isLoading = false)
    },
    validateNewPassword() {
      if (!this.form.password || this.form.password.length === 0) {
        this.newPasswordError = true;
        this.newPasswordErrorMessage = 'Şifre boş olamaz.';
        return;
      }
      const password = this.form.password;
      const minLength = 8;
      const hasUpperCase = /[A-Z]/.test(password);
      const hasLowerCase = /[a-z]/.test(password);
      const hasNumber = /\d/.test(password);
      const hasSpecialChar = /[!@#$%^&*(),.?:{}|<>-_]/.test(password);

      if (password.length < minLength) {
        this.newPasswordError = true;
        this.newPasswordErrorMessage = 'Şifre en az 8 karakter olmalıdır.';
      } else if (!hasUpperCase) {
        this.newPasswordError = true;
        this.newPasswordErrorMessage = 'Şifre en az bir büyük harf içermelidir.(ABCDEF)';
      } else if (!hasLowerCase) {
        this.newPasswordError = true;
        this.newPasswordErrorMessage = 'Şifre en az bir küçük harf içermelidir.(abcdef)';
      } else if (!hasNumber) {
        this.newPasswordError = true;
        this.newPasswordErrorMessage = 'Şifre en az bir rakam içermelidir.(1,2,3,4,5,6,7,8,9,0 vb.)';
      } else if (!hasSpecialChar) {
        this.newPasswordError = true;
        this.newPasswordErrorMessage = 'Şifre en az bir sembol içermelidir.(&,_,-,@ vb.)';
      } else {
        this.newPasswordError = false;
        this.newPasswordErrorMessage = '';
      }
    },
    validateConfirmPassword() {
      this.confirmNewPasswordError = this.form.password !== this.form.password_confirmation;
    },
    generateP(len) {
      let pass = ""
      const str =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*(),.?:{}|<>-_"

      for (let i = 1; i <= len; i++) {
        let char = Math.floor(Math.random() * str.length + 1)
        pass += str.charAt(char)
      }

      return pass
    },
  },
  watch:{
    'form.password': function(){
      this.validateNewPassword();
      this.validateConfirmPassword();
    },
    'form.password_confirmation': function(){
      this.validateNewPassword();
      this.validateConfirmPassword();
    }
  }
}
</script>

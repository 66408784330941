<template>
	<div>
		<section class="section is-main-section">
			<div class="column is-6 is-offset-3">
				<tiles>
					<!--<profile-update-form class="tile is-child" />-->
					<password-update-form class="tile is-child" />
				</tiles>
			</div>
		</section>
	</div>
</template>

<script>
	import ProfileUpdateForm from "@/components/ProfileUpdateForm"
	import PasswordUpdateForm from "@/components/PasswordUpdateForm"
	import Tiles from "@/components/Tiles"
	export default {
		name: "Profile",
		components: {
			Tiles,
			PasswordUpdateForm,
			ProfileUpdateForm,
		},
	}
</script>
